<template>
  <div class="app-sub-bar bg-white">
    <div class="mr-2 hamburger show-mobile"><font-awesome-icon icon="bars" @click="toggleSideBar" /></div>
    <div class="logo"><img :src="getLogoSrc" /></div>
    <div class="menu-center bg-white">
      <!--Homve-->
      <div class="menu-item bg-white" :class="isLinkActive('retailer')" @click="navigateTo('retailer')">{{ $t("Home") }}</div>
      <!--Products-->
      <div class="menu-item bg-white hide-mobile" :class="isLinkActive('products')" @click="navigateTo('productcategories')">{{ $t("Products") }}</div>
      <!--My Account-->
      <div class="menu-item bg-white hide-mobile" :class="isLinkActive('myaccount')">
        {{ $t("My Account") }}
        <div class="sub-menu">
          <div @click="navigateTo('profile')">{{ $t("Profile") }}</div>
          <div @click="navigateTo('orderhistory')">{{ $t("Order History") }}</div>
          <div @click="navigateTo('favorites')">{{ $t("My Favorites") }}</div>
          <div @click="navigateTo('logout')" v-if="$store.getters.isAuthenticated()">{{ $t("Log out") }}</div>
        </div>
      </div>
      <!--Contractor-->
      <div class="menu-item bg-white" :class="isLinkActive('contractor')">
        {{ $t("Contractor Portal") }}
        <div class="sub-menu" v-if="$store.getters.isAuthenticated() && !$store.getters.isContractor">
          <div class="menu-info">Your account is not<br />associated with a<br />contractor account.</div>
        </div>
        <div class="sub-menu" v-else>
          <div @click="navigateTo('contractorinvoices')">{{ $t("Invoices") }}</div>
          <div @click="navigateTo('contractorstatements')">{{ $t("Statements") }}</div>
          <div @click="navigateTo('contractorsearch')">{{ $t("Search") }}</div>
          <div @click="navigateTo('contractorpayment')" v-show="$store.getters.isAllowedPayments">{{ $t("Payments") }}</div>
          <div @click="navigateTo('contractorregulatory')" v-show="$store.getters.isPrimary">{{ $t("Regulatory") }}</div>
          <div @click="navigateTo('contractorusers')" v-show="$store.getters.isAllowedPayments">{{ $t("Users") }}</div>
        </div>
      </div>
      <!--Admin-->
      <div class="menu-item bg-white hide-mobile" :class="isLinkActive('admin')" v-if="$store.getters.isRetailerAdmin">
        {{ $t("Admin") }}
        <div class="sub-menu">
          <div @click="navigateTo('contractorlogo')">{{ $t("Logo") }}</div>
          <div @click="navigateTo('contractorbannerads')">{{ $t("Banner Ads") }}</div>
          <div @click="navigateTo('contractorsplashpage')">{{ $t("Splash Page") }}</div>
          <div @click="navigateTo('contractorhomepage')">{{ $t("Home Page") }}</div>
        </div>
      </div>
      <!--Combined Product Tools-->
      <div class="menu-item bg-white hide-mobile" v-if="$store.getters.isRetailerAdmin" @click="openComboTools">
        {{ $t("Combine Tools") }}
        <!-- <div class="sub-menu">
					<div @click="navigateTo('combolist')">{{ $t("Manage Combined Products") }}</div>
					<div v-show="isLinkActive('products')" @click="showCombine = !showCombine">{{ $t("Start New Combine") }}</div>
					<div v-show="isLinkActive('products')" @click="clearCombineSelections">{{ $t("Clear Selections") }}</div>
					<div v-show="isLinkActive('products')" @click="createCombination">{{ $t("Create From Selected") }}</div>
				</div> -->
      </div>
    </div>
    <!--search-->
    <div class="float-right bg-white search-input-container" :class="{ show: showSearch }">
      <input id="search-input" :class="{ show: showSearch }" type="text" :placeholder="$t('Search')" v-model="searchPhrase" v-on:keyup.enter="search" />
      <font-awesome-icon icon="times" class="pb-1" v-show="showSearch" @click="showSearch = false" />
    </div>
    <div class="float-right pr-3 bg-white">
      <font-awesome-icon icon="search" @click="openSearch" />
    </div>
  </div>
</template>

<script>
import { eventBus } from "@/main";
import * as _publicService from "@/services/publicService";
export default {
  name: "cAppSubBar",
  components: {},
  props: [],
  data: () => ({
    logoBase64: "",
    retailerId: 0,
    searchPhrase: "",
    showSearch: false,
    showCombine: false,
  }),
  methods: {
    load() {
      if (this.retailerId === 0) return;
      _publicService.getRetailerLogo((response) => {
        //console.info("cAppTopBar|load|getRetailerLogo fired!", response);
        if (response.Success) {
          this.logoBase64 = response.Base64EncodedString;
        }
      });
    },
    clearCombineSelections() {
      eventBus.$emit("clearCombineSelections");
    },
    createCombination() {
      eventBus.$emit("createCombination");
    },
    isLinkActive(menuName) {
      let route = this.$route;
      if (route.name == null) return;
      if (menuName === "retailer" && route.name.indexOf("retailer") > -1) {
        return "active";
      } else if (menuName === "products" && route.name.indexOf("products") > -1) {
        return "active";
      } else if (
        menuName === "contractor" &&
        (route.name.indexOf("contractorinvoices") > -1 ||
          route.name.indexOf("contractorstatements") > -1 ||
          route.name.indexOf("contractorsearch") > -1 ||
          route.name.indexOf("contractorpayment") > -1 ||
          route.name.indexOf("contractorusers") > -1 ||
          route.name.indexOf("contractorregulatory") > -1)
      ) {
        return "active";
      } else if (
        menuName === "myaccount" &&
        (route.name.indexOf("myaccount") > -1 || route.name.indexOf("profile") > -1 || route.name.indexOf("orderhistory") > -1 || route.name.indexOf("favorites") > -1)
      ) {
        return "active";
      } else if (
        menuName === "admin" &&
        (route.name.indexOf("contractorlogo") > -1 ||
          route.name.indexOf("contractorbannerads") > -1 ||
          route.name.indexOf("contractorsplashpage") > -1 ||
          route.name.indexOf("contractorhomepage") > -1)
      ) {
        return "active";
      }
      return "";
    },
    navigateTo(routeName) {
      this.showUserMenu = false;
      //don't try to route to the current route
      if (this.$route.name == routeName) return;
      let route = { name: routeName, params: {} };
      if (routeName == "retailer") {
        route.params = JSON.parse('{ "retailerId":"' + this.$store.getters.retailerId + '"}');
      }

      //this.$router.push({ name: routeName });
      this.$router.push(route);
    },
    openSearch() {
      if (this.showSearch == true) {
        this.search();
      } else {
        this.showSearch = true;
        let el = document.getElementById("search-input");
        if (el) el.focus();
      }
    },
    search() {
      if (this.searchPhrase == null || this.searchPhrase.length == 0) return;
      this.showSearch = false;
      var numbers = /^[0-9]+$/;
      if (this.searchPhrase.match(numbers)) {
        this.$store.commit("dispatchMessage", {
          color: "warning",
          text: this.$t("UpcSearchNotSupported"),
        });
        return;
      }

      let url = "/products?phrase=" + this.searchPhrase.toLowerCase();
      if (this.$route.fullPath.toLowerCase() === url) return;
      //this.searchPhrase = "";
      this.$router.push(url);
    },
    openComboTools() {
      let el = document.getElementById("combo-tools-drawer");
      if (el) el.classList.add("open");
    },
    toggleSideBar() {
      let el = document.getElementById("side-drawer");
      if (el) {
        if (el.classList.value.indexOf("open") < 0) {
          el.classList.add("open");
          //el.style.display = "block";
        } else {
          //el.style.display = "none";
          el.classList.remove("open");
        }
      }
    },
  },
  computed: {
    getLogoSrc: function () {
      if (!this.logoBase64) return "/assets/shopping/images/logo.png";
      return this.logoBase64.length > 0 ? "data:image/jpeg;base64," + this.logoBase64 : "/assets/shopping/images/logo.png";
    },
  },
  watch: {
    "$store.state.common.retailerId": {
      handler: function (newvalue) {
        this.retailerId = newvalue;
        //console.info("cAppSubBar|watch|user:", newvalue);
        //if (retailerId != 0) this.retailerId = newStore.retailerId;
      },
      deep: true,
      immediate: true,
    },
    showCombine: function (newValue) {
      eventBus.$emit("showCombine", newValue);
    },
  },
  mounted: function () {
    //console.log("cAppTopBar|mounted|retailerId: " + this.retailerId);
    _publicService.getRetailerLogo((response) => {
      //console.info("cAppTopBar|mounted|getRetailerLogo fired!", response)
      if (response.Success) {
        this.logoBase64 = response.Base64EncodedString;
        this.load();
      }
    });
  },
};
</script>

<style>
.bg-white {
  background-color: white;
}
.app-sub-bar {
  position: fixed;
  top: var(--topBarHeight);
  left: 0;
  right: 0;
  color: var(--baseColor);
  /* font-size: 0.9em; */
  background: none;
  height: var(--subBarHeight);
  line-height: var(--subBarHeight);
  padding: 0 10px;
  z-index: 1;
}
.app-sub-bar > div,
.app-sub-bar > div > div {
  display: inline-block;
}
.app-sub-bar .menu-center {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  width: 100%;
  z-index: -1;
}
.app-sub-bar .logo img {
  max-height: 40px;
  max-width: 100px;
  margin-right: 15px;
}
.app-sub-bar .menu-item {
  padding: 0 15px;
  margin: 0 5px;
  cursor: pointer;
  border-bottom: 3px solid transparent;
}

.app-sub-bar .menu-item:hover {
  border-color: var(--accentBackgroundColor);
}
.app-sub-bar .menu-item.active {
  border-color: var(--buttonHoverColor);
}
.app-sub-bar .svg-inline--fa {
  color: var(--baseColor);
}
.menu-info {
  line-height: normal;
  padding: 10px;
  cursor: auto;
}
.sub-menu {
  position: absolute;
  /* top: calc(var(--subBarHeight) + 3px); */
  display: none;

  border-top: 3px solid var(--accentBackgroundColor);
  background-color: white;
  text-align: left;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
}
.sub-menu > div {
  padding: 0 10px;
}
.sub-menu > div:hover {
  background-color: #e9ecef;
}
.menu-item:hover .sub-menu {
  display: block;
}
.app-sub-bar > div.search-input-container {
  visibility: hidden;
  position: absolute;
  top: 64px;
  right: 10px;
  width: 0;
  height: 35px;
  opacity: 0;
  transition: width 0.5s, opacity 1s, visibility 3s;
}
.app-sub-bar > div.search-input-container.show {
  visibility: visible;
  width: 100%;
  opacity: 1;
  transition: width 0.5s, opacity 1s, visibility 0.1s;
}
#search-input {
  position: relative;
  top: -10px;
  outline: none;
  border: none;
  border-bottom: 1px solid;
  padding: 0;
  height: 30px;
  width: 0;
  opacity: 0;
  transition: width 0.5s, opacity 1s;
}
#search-input:focus {
  box-shadow: none !important;
  border: none !important;
  border-bottom: 1px solid !important;
}
#search-input.show {
  width: 97%;
  margin-left: 15px;
  opacity: 100;
  transition: width 0.5s;
}

.show-mobile {
  visibility: hidden;
}
@media screen and (max-width: 576px) {
  .app-sub-bar .logo img {
    max-width: 80px;
  }
  .app-sub-bar .menu-item {
    padding: 0 10px;
  }
  .app-sub-bar .menu-center {
    text-align: left;
    padding-left: 120px;
  }
  #search-input.show {
    width: 90%;
  }
  .app-sub-bar > div.search-input-container {
    top: 48px;
  }
  .app-sub-bar > div.hamburger {
    font-size: 1.2em;
  }

  .show-mobile {
    visibility: visible;
  }

  .hide-mobile,
  .app-sub-bar > div.hide-mobile,
  .app-sub-bar > div > div.hide-mobile {
    display: none;
  }
}
</style>
